<template>
  <v-card to="/c/stallionreview" class="pa-3 pb-0" tile>
    <p class="caption mb-2">INFO</p>
    <v-sheet color="deep-orange lighten-3" rounded="50px">
      <v-card-title>New Circle!</v-card-title>
      <v-card-text>
        Announcing AUN's new Digital Magazine <b>Stallion:Review</b>
        live on Bubbl!
      </v-card-text>
    </v-sheet>
    <div>
      <v-list dense two-line>
        <v-subheader
          >Latest Post trending now
          <v-icon color="red"> {{ mdiFire }} </v-icon></v-subheader
        >
        <v-list-item
          v-if="post.slug"
          :to="`/c/stallionreview/p/${post.slug}`"
          class="pr-0"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ post.caption }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-html="$options.filters.truncate(post.parsed_html, 100, '...')"
            >
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar color="orange" tile size="80px">
            <v-img v-if="post.image" width="80" height="80" :src="post.image">
            </v-img>

            <span v-else class="white--text">
              SR
            </span>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>
<script>
import http from '@/plugins/http';
// icons
import { mdiFire } from '@mdi/js';
export default {
  name: 'InfoCard',
  props: ['slug'],
  data: () => ({
    post: {},
    loading: false,
    mdiFire
  }),
  methods: {
    getPost() {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      const headers = {
        Authorization: `token ${token}`
      };
      if (token) {
        http
          .get(`/v1/post/${this.slug}/`, { headers })
          .then(response => {
            this.post = response.data.data;
          })
          .catch(error => {
            console.log('Error => ', error);
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Error fetching Post',
              style: 'error'
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        http
          .get(`/v1/post/${this.slug}/`)
          .then(response => {
            this.post = response.data.data;
          })
          .catch(error => {
            console.log('Error ! =>', error);
            if (error.response.status == 404) {
              this.$router.push({
                name: '404',
                query: { lost: 'post' },
                params: { '0': this.$route.path }
              });
              return;
            }
            this.$store.dispatch('SHOW_TOAST', {
              message: 'Error fetching Post',
              style: 'error'
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },

  mounted() {
    this.getPost();
  }
};
</script>
<style scoped></style>
